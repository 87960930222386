<template>
	<div>
        没有权限访问相应的资源
	</div>
</template>

<script>

export default {
	name: 'Page403',
	data () {
		return {
		}
	},
	computed: {
		
	}
}
</script>
